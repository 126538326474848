(function ( $ ) {
	$.fn.slider = function ( options ) {
		options = $.extend({
			interval  : 5000,				// Интервал смены слайда
			speed     : 300, 				// Скорость смены слайдов
			onpage    : 1, 				// количество видимых блоков
			classItm  : '.slider_item',		// класс элемента слайда
			automove  : false,
			show_dots : false,          //управляющие точки
			show_arrow: true,           //управляющие стрелки
			loop      : false,            //стрелки по кругу без границы
			absolute  : false
		}, options);

		var make = function () {
			var self      = this,	// перепишем объект
			    id_timeout,		// id таймера
			    mql       = window.matchMedia('all and (max-width: 1000px)'),
			    is_mobile = mql.matches;

			$(document).ready(function () {

				// инициация слайдера
				var count          = $(self).find(options.classItm).length,
				    items          = $(self).find(options.classItm),
				    slider_list,
				    document_width = window.innerWidth;
				// если количество блоков меньше минимального -> уходим
				if( count <= options.onpage ) return;

				// оборачиваем все элементы слайдера в блок
				items.wrapAll('<div class="slider_list"></div>');
				$(self).find('.slider_list').wrap('<div class="b_slider_wrap"></div>');

				slider_list = $(self).find('.slider_list');

				if( options.show_dots ) {
					// добавление навигационного блока
					$(self).append('<div class="b_slider_nav_points"></div>');
				}

				if( options.show_arrow ) {
					$(self).append('<div class="slider_controls">' +
						'<div class="slide_control left"></div>' +
						'<div class="slide_control right"></div>' +
						'</div>'
					);
				}


				function init() {

					if(!options.absolute) {
						change_element_width();
					}
					// устанавливаем ширину обертки слайдеров, и сдвигаем в начало
					$(self).find('.slider_list').css('left', '0px');

					// добавление навигационных точек
					$(self).find('.b_slider_nav_points').empty();
					for ( var i = 0; i < Math.ceil(count / options.onpage); i++ ) {
						$(self).find('.b_slider_nav_points').append('<div class="slider_nav_point_itm"></div>');
					}

					$(self).find('.slider_nav_point_itm').first().addClass('active');
					slider_list.attr('data-slide', 0);

					items.removeClass('active');
					items.first().addClass('active');

					if(options.absolute){
						items.removeClass('prev prevAll next nextAll');
						items.eq(0).next().addClass('next');
						items.eq(0).next().nextAll().addClass('nextAll');
						items.last().removeClass('prev prevAll next nextAll').addClass('prev');
					}

					// запуск анимации
					do_animate();

				}

				function change_element_width() {
					var onpage = options.onpage;

					if( is_mobile ) {
						onpage = 1;
					}

					var width = count * 100;

					slider_list.css('width', width + '%');

					items.each(function () {

						$(this).css('width', 100 / count / options.onpage + '%');

						if( count <= 1 ) {
							self.find('.slide_control.right').css('display', 'none');
						}
					});
				}

				// Анимация
				function do_animate() {
					var bottom_pos = $(self).offset().top + $(self).height(),
						scroll = $(document).scrollTop(),
						show = bottom_pos > scroll; //автослайд только когда слайдер в видимости окна

					if( options.automove) {
						clearTimeout(id_timeout);
						id_timeout = setTimeout(function () {
							if(show) {
								var slide_page = parseInt(slider_list.attr('data-slide')); //текущая страница слайдера

								move_to(slide_page + 1);
							}

							do_animate();
						}, options.interval);
					}
				}

				init();

				// сдвиг к точке
				function move_to( num ) {

					if( num < 0 ) {
						num = Math.round(count / options.onpage - 1);
					} else if( num >= Math.ceil(count / options.onpage) ) {
						num = 0;
					}

					if(!options.absolute) {

						var moving = num * 100;

						$(self).find('.slider_list').stop();
						$(self).find('.slider_list').animate(
							{'left': -moving + '%'}
							, options.speed
						);

					}else{
						items.removeClass('prev prevAll next nextAll');
						items.eq(num).prev().prevAll().addClass('prevAll');
						items.eq(num).prev().addClass('prev');
						items.eq(num).next().addClass('next');
						items.eq(num).next().nextAll().addClass('nextAll');

						if(num === 0){
							items.last().removeClass('prev prevAll next nextAll').addClass('prev');
						}
						if(num === count-1){
							items.eq(0).removeClass('prev prevAll next nextAll').addClass('next');
						}
					}

					slider_list.attr('data-slide', num);
					if( options.loop !== true ) {
						visible_slider_btn(num);
					}

					items.removeClass('active');
					items.eq(num).addClass('active');
					var points = $(self).find('.slider_nav_point_itm');
					points.removeClass('active');
					points.eq(num).addClass('active');
					do_animate();
				}


				function visible_slider_btn( page ) {
					var max_page = count - options.onpage;

					if( options.show_arrow ) {
						if( page === 0 ) {
							$(self).find('.slide_control.left').css('display', 'none');
							$(self).find('.slide_control.right').css('display', 'block');

							return true;
						}

						if( page === max_page ) {
							$(self).find('.slide_control.left').css('display', 'block');
							$(self).find('.slide_control.right').css('display', 'none');

							return true;
						}

						$(self).find('.slide_control.left').css('display', 'block');
						$(self).find('.slide_control.right').css('display', 'block');
					}
				}


				// обработчик на навигационные точки
				$(self).find('.slider_nav_point_itm').on('click', function () {
					var act = $(this).index();
					move_to(act);
				});

				$(self).find('.slide_control.left').on('click', function () {
					//ищим у нажатой кнопки родителя slider а заметем внутри него элемент slider_items
					var slide_page = parseInt(slider_list.attr('data-slide')); //текущая страница слайдера

					slide_page = slide_page - 1; // смещаем страницу влево

					if( options.loop ) {
						move_to(slide_page);
						return false;
					}
					// если не ушли за границу то делаем слайд
					if( slide_page >= 0 ) {
						move_to(slide_page);
					}

				});

				$(self).find('.slide_control.right').on('click', function () {
					//ищим у нажатой кнопки родителя slider а заметем внутри него элемент slider_items
					var slide_page = parseInt(slider_list.attr('data-slide')); //текущая страница слайдера

					slide_page = slide_page + 1; // смещаем страницу влево

					if( options.loop ) {
						move_to(slide_page);
						return false;
					}

					// если не ушли за границу то делаем слайд
					if( slide_page < count / options.onpage ) {
						move_to(slide_page);
					}
				});


				$(window).on('resize', function () {
					document_width = window.innerWidth;
					if(!options.absolute) {
						change_element_width();
					}
				});


				/**     Пролистывание свайпами    **/

				var touchend = 1,  //закончено движение пальцем или нет
				    slider   = slider_list,
				    prev;

				slider.on('touchstart click', function ( ev ) {
					if( ev.originalEvent.changedTouches ) prev = ev.originalEvent.changedTouches[0].pageX;   //записываем координаты начального прикосновения
				});

				slider.on('touchmove click', function ( ev ) {
					if( touchend === 1 ) {   //если движение пальцем закончено
						if( ev.originalEvent.changedTouches ) {
							var X = ev.originalEvent.changedTouches[0].pageX;
							if( Math.abs(X - prev) > 120 ) {//слайд совершается только при сильном свайпе
								touchend = 0;
								var page = parseInt(slider.attr('data-slide'));
								if( X < prev ) {     //сравниваем текущии координаты с начальным положением пальца
									page++;
								} else {
									page--;
								}

								if( options.loop ) {
									move_to(page);
									return false;
								}

								if( page < count / options.onpage ) {
									move_to(page);
								}
							}
						}
					}
				});
				slider.on('touchend click', function ( ev ) {
					touchend = 1;
				});

				/**     /Пролистывание свайпами    **/

			});

		};

		return this.each(make);
	};
})(jQuery);